import React, { Component } from "react"
import Header from "../components/header/header"
import SEO from "../components/seo"
import { TransparentText } from "../styled-components/transparent-text"
import { Error404 } from "../styled-components/404-styles"
import { css } from "@emotion/core"

const urls = [
  "https://giphy.com/embed/10DETe23NXDnDq",
  "https://giphy.com/embed/QLXPK5HFpzCjS",
  "https://giphy.com/embed/BsQAVgY6ksvIY",
  "https://giphy.com/embed/3ohzdYJK1wAdPWVk88",
  "https://giphy.com/embed/26gJzc8gAkTImtSLe",
  "https://giphy.com/embed/11tIB893VLShXi",
  "https://giphy.com/embed/9JwWxcR0SUSC195IWL",
  "https://giphy.com/embed/GDnomdqpSHlIs",
]

class NotFoundPage extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <SEO title="Error" />
        <Header />
        <Error404>
          <h1>
            <TransparentText
              css={css`
                text-align: center;
              `}
            >
              Error: Page does not exist.
            </TransparentText>
          </h1>
          <iframe
            src={urls[Math.floor(Math.random() * Math.floor(urls.length))]}
            width="750"
            height="750"
            frameBorder="0"
            className="giphy-embed"
            title="Oops"
            allowFullScreen
          />
        </Error404>
      </>
    )
  }
}

export default NotFoundPage
