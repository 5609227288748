import styled from "@emotion/styled"

export const Error404 = styled.section`
  background: #222;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 11vh;
  }
`
